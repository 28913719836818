// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-authors-page-js": () => import("./../../../src/templates/authors-page.js" /* webpackChunkName: "component---src-templates-authors-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-page-js": () => import("./../../../src/templates/categories-page.js" /* webpackChunkName: "component---src-templates-categories-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-portfolio-list-js": () => import("./../../../src/templates/portfolio-list.js" /* webpackChunkName: "component---src-templates-portfolio-list-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */),
  "component---src-templates-project-list-js": () => import("./../../../src/templates/project-list.js" /* webpackChunkName: "component---src-templates-project-list-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-tags-portfolio-js": () => import("./../../../src/templates/tags-portfolio.js" /* webpackChunkName: "component---src-templates-tags-portfolio-js" */),
  "component---src-templates-tags-project-js": () => import("./../../../src/templates/tags-project.js" /* webpackChunkName: "component---src-templates-tags-project-js" */),
  "component---src-templates-view-list-js": () => import("./../../../src/templates/view-list.js" /* webpackChunkName: "component---src-templates-view-list-js" */),
  "component---src-templates-view-page-js": () => import("./../../../src/templates/view-page.js" /* webpackChunkName: "component---src-templates-view-page-js" */)
}

